<template>
  <BasePanelWithHeader :collapsable="true" headerText="Status History">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="selectedApplicationApplicationStatusHistories"
      :search="false"
      :loading="applicationStatusHistoriesLoading"
      sortBy="CreatedDate"
      sortDesc
    >
      <template v-slot:item.Status="{ item }">{{
        item.Status.Description
      }}</template>
      <template v-slot:item.Reason="{ item }">{{
        item.Reason ? item.Reason.Description : ''
      }}</template>
      <template v-slot:item.CreatedBy="{ item }"
        >{{ item.CreatedBy.FirstName }} {{ item.CreatedBy.LastName }}</template
      >
      <template v-slot:item.CreatedDate="{ item }">{{
        item.CreatedDate | formatDateTime
      }}</template>
    </BaseSimpleDataTable>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {
    ...get('application', [
      'selectedApplicationApplicationStatusHistories',
      'applicationStatusHistoriesLoading',
    ]),
    headers() {
      return [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Application Id',
          align: 'center',
          sortable: true,
          value: 'ApplicationId',
          visible: false,
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'Status',
          visible: true,
        },
        {
          text: 'Reason',
          align: 'center',
          sortable: true,
          value: 'Reason',
          visible: true,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedBy',
          visible: true,
        },
        {
          text: 'Created Date',
          align: 'center',
          sortable: true,
          value: 'CreatedDate',
          visible: true,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('application', [
      'loadSelectedApplicationApplicationStatusHistories',
    ]),
  },
  watch: {},
}
</script>
<style scoped></style>
