<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary text-h5 white--text">
        Property
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col sm="12">
            <v-text-field
              label="Parcel Id"
              v-model="selectedTaxableProperty.ParcelId"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <LuCounty
              label="County"
              :items="filteredCounties"
              v-model="selectedTaxableProperty.CountyId"
              :rules="[(v) => !!v || 'required']"
              clearable
            ></LuCounty>
          </v-col>
        </v-row>
        <v-row
          ><v-btn small class="primary" @click="copyFromMailingAddress"
            >Copy from Mailing Address</v-btn
          ></v-row
        >
        <v-row>
          <v-col sm="12">
            <v-text-field
              label="Street Address 1"
              v-model="selectedTaxableProperty.StreetAddress1"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              label="Street Address 2"
              v-model="selectedTaxableProperty.StreetAddress2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <LuCity
              label="City"
              v-model="selectedTaxableProperty.CityId"
              :items="filteredCities"
              :rules="[(v) => !!v || 'required']"
              clearable
            ></LuCity>
          </v-col>
          <!-- </v-row>
          <v-row> -->
          <v-col cols="4">
            <LuState
              label="State"
              :itemText="$vuetify.breakpoint.smAndDown ? 'Abbr' : 'Name'"
              v-model="selectedTaxableProperty.StateId"
              disabled
            ></LuState>
          </v-col>
          <!-- </v-row>
          <v-row> -->
          <v-col cols="7" md="4">
            <v-text-field
              label="Zip"
              v-model="selectedTaxableProperty.Zip"
              v-mask="['#####', '#####-####']"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveTaxablePropertyDetails"
          :disabled="saving"
          color="primary"
          >{{ inDialog ? 'Save' : 'Next' }}</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { mask } from 'vue-the-mask'
import { get, sync, commit, call } from 'vuex-pathify'
import TaxableProperty from '@classes/TaxableProperty'
import LuCity from '@components/select/LuCity/LuCity-a-single'
import LuCounty from '@components/select/LuCounty/LuCounty-single'
import LuState from '@components/select/LuState/LuState-single'
export default {
  props: {
    contact: { type: Object },
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  directives: {
    mask,
  },
  components: {
    LuCity,
    LuCounty,
    LuState,
  },
  data() {
    return {
      valid: true,
      selectedTaxableProperty: new TaxableProperty(),
    }
  },
  created() {
    this.loadLuCounties()
    this.loadLuCities()
    if (!this.inDialog) {
      this.addTaxableProperty(this.contact.Id)
    }
  },
  computed: {
    ...get('taxableProperty', ['saving']),
    ...get('luCounty', ['luCounties']),
    ...get('luCity', ['luCities']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
    filteredCounties() {
      if (
        this.selectedTaxableProperty.CityId > 0 &&
        this.luCities &&
        this.luCities.length > 0
      ) {
        return this.luCities.find(
          (i) => i.Id == this.selectedTaxableProperty.CityId
        ).Counties
      } else {
        return null
      }
    },
    filteredCities() {
      if (
        this.selectedTaxableProperty.CountyId > 0 &&
        this.luCounties &&
        this.luCounties.length > 0
      ) {
        return this.luCounties.find(
          (i) => i.Id == this.selectedTaxableProperty.CountyId
        ).Cities
      } else {
        return null
      }
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('taxableProperty', ['saveTaxableProperty']),
    loadLuCounties: call('luCounty/loadLuCounties'),
    loadLuCities: call('luCity/loadLuCities'),
    toastMessage: call('messages/toastMessage'),
    async validate() {
      await this.$refs.form.validate()
    },
    editTaxableProperty(entry) {
      this.selectedTaxableProperty = new TaxableProperty(entry)
      this.modalText = 'Edit Taxable Property'
      // this.dialog = !this.dialog
    },
    copyFromMailingAddress() {
      this.selectedTaxableProperty.StreetAddress1 = this.contact.MailingAddress1
      this.selectedTaxableProperty.StreetAddress2 = this.contact.MailingAddress2
      this.selectedTaxableProperty.Zip = this.contact.Zip
      //this.selectedTaxableProperty.StateId = this.contact.StateId
      var cities =
        this.filteredCities && this.filteredCities.length > 0
          ? this.filteredCities
          : this.luCities
      var city = cities.find(
        (i) => i.Name.toLowerCase() == this.contact.City.toLowerCase()
      )
      if (city) {
        this.selectedTaxableProperty.CityId = city.Id
      } else {
        this.toastMessage({ message: 'unable to select city', color: 'error' })
      }
    },
    addTaxableProperty(contactId) {
      this.modalText = 'Insert Taxable Property'
      if (contactId) {
        this.selectedTaxableProperty = new TaxableProperty({
          ContactId: contactId,
        })
      } else {
        this.selectedTaxableProperty = new TaxableProperty()
      }
      // this.dialog = !this.dialog
    },

    async saveTaxablePropertyDetails() {
      await this.validate()
      if (this.valid) {
        try {
          let res = await this.saveTaxableProperty(
            this.selectedTaxableProperty.root()
          )
          this.$emit('refresh')
          console.devlog(res.data)
          this.$emit('itemAdded', res.data)
          this.selectedTaxableProperty = new TaxableProperty()
          this.$refs.form.resetValidation()
        } catch (err) {}
        // this.dialog = false
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      // this.dialog = false
      this.$emit('update:dialog', false)
      // this.$emit('change')
      this.selectedTaxableProperty = new TaxableProperty()
      this.$refs.form.resetValidation()
    },
  },
  watch: {
    'selectedTaxableProperty.CityId'(nval, oval) {
      if (
        nval &&
        nval > 0 &&
        !this.selectedTaxableProperty.CountyId > 0 &&
        this.luCities &&
        this.luCities.length > 0
      ) {
        var counties = this.luCities.find(
          (i) => i.Id == this.selectedTaxableProperty.CityId
        ).Counties
        if (counties.length == 1) {
          this.selectedTaxableProperty.CountyId = counties[0].Id
        }
      }
    },
  },
}
</script>
<style scoped></style>
