<template>
  <div>
    <ApplicationSummary
      :application="application"
      :canEdit="canEdit"
    ></ApplicationSummary>
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-bold">
          Please enter all income generating household members here:
        </div>
      </v-col>
    </v-row>
    <ApplicationHouseholdMembers
      :canEdit="canEdit"
    ></ApplicationHouseholdMembers>
    <!-- <ApplicationStatusHistory></ApplicationStatusHistory>-->
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-bold">
          Please use this section to upload required documents:
        </div>
      </v-col>
    </v-row>
    <ApplicationDocuments :canEdit="canEdit"></ApplicationDocuments>
    <!--<ApplicationNotes></ApplicationNotes>-->
  </div>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import ApplicationHouseholdMembers from '@components/form/consumer/ApplicationHouseholdMembers'
import ApplicationDocuments from '@components/form/consumer/ApplicationDocuments'
// import ApplicationNotes from '@components/form/consumer/ApplicationNotes'
import ApplicationStatusHistory from '@components/form/consumer/ApplicationStatusHistory'
import ApplicationSummary from '@components/form/consumer/ApplicationSummary'
export default {
  //model: {
  //	prop: ''
  //},
  created() {
    this.loadSelectedApplicationApplicationDocuments()
    // this.loadSelectedApplicationApplicationNotes()
    this.loadSelectedApplicationApplicationStatusHistories()
    this.loadSelectedApplicationHouseholdMembers()
  },
  props: {
    application: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    ApplicationHouseholdMembers,
    ApplicationDocuments,
    // ApplicationNotes,
    ApplicationStatusHistory,
    ApplicationSummary,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...call('application', [
      'loadSelectedApplicationApplicationDocuments',
      // 'loadSelectedApplicationApplicationNotes',
      'loadSelectedApplicationApplicationStatusHistories',
      'loadSelectedApplicationHouseholdMembers',
    ]),
  },
  watch: {},
}
</script>
<style scoped></style>
