import { render, staticRenderFns } from "./ConsumerForm.vue?vue&type=template&id=4d161477&scoped=true"
import script from "./ConsumerForm.vue?vue&type=script&lang=js"
export * from "./ConsumerForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d161477",
  null
  
)

export default component.exports