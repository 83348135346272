<template>
  <BasePanelWithHeader :collapsable="true" headerText="Documents">
    <!--<template v-slot:header>
      Documents<BaseTooltipButton
        v-if="canEdit"
        icon="mdi-plus-circle"
        iconColor="white"
        @click.stop="addDocument"
      >
        Upload Document
      </BaseTooltipButton>
    </template>-->
    <template v-slot:header>
      <v-row>
        <v-col cols="12" sm="auto">Documents</v-col>
        <v-col cols="12" sm="auto">
          <BaseTooltipButton
            v-if="canEdit"
            icon="mdi-plus-circle"
            iconColor="white"
            buttonClass="primary--text"
            @click.stop="addDocument"
            buttonRightText="Add Document"
            :iconButton="false"
          >
            Upload Document
          </BaseTooltipButton>
        </v-col>
      </v-row>
    </template>
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="mappedApplicationDocuments"
      :searchText="searchText"
      :loading="applicationDocumentsLoading"
      sortBy="CreatedDate"
      sortDesc
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          v-if="canEdit"
          small
          @click="$refs.applicationDocumentForm.editApplicationDocument(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Application Document
        </BaseTooltipButton>
        <BaseTooltipButton
          small
          @click="downloadDocument(item.DocumentId)"
          iconColor="primary"
          icon="mdi-download"
          >Download
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          v-if="canEdit && item.CreatedById == currentUser.Id"
          small
          @click="$refs.applicationDocumentDelete.deleteConfirm(item.Document)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Application Document
        </BaseTooltipButton>
      </template>
      <template v-slot:item.CreatedBy="{ item }"
        >{{ item.CreatedBy.FirstName }} {{ item.CreatedBy.LastName }}</template
      >
      <template v-slot:item.CreatedDate="{ item }">{{
        item.CreatedDate | formatDateTime
      }}</template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="applicationDocumentDelete"
      :delete="deleteDocument"
      @refresh="refresh"
    >
      Are you sure you want to delete this application document?
    </BaseDeleteConfirm>
    <ApplicationDocumentDialogForm
      ref="applicationDocumentForm"
      :householdMembers="selectedApplicationHouseholdMembers"
      @refresh="refresh"
    >
    </ApplicationDocumentDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { authComputed } from '@state/helpers'
import { get, sync, commit, call } from 'vuex-pathify'
import ApplicationDocumentDialogForm from '@components/form/consumer/ApplicationDocumentDialog'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    ApplicationDocumentDialogForm,
  },
  data() {
    return {
      searchText: '',
    }
  },
  computed: {
    ...authComputed,
    ...get('application', [
      'selectedApplicationApplicationDocuments',
      'selectedApplicationApplicationDocumentsId',
      'selectedApplicationHouseholdMembers',
      'applicationDocumentsLoading',
    ]),
    mappedApplicationDocuments() {
      var list = this.selectedApplicationApplicationDocuments.map((i) => {
        return {
          ...i,
          CreatedBy: i.Document.CreatedBy,
          CreatedById: i.Document.CreatedById,
          documentType: i.Document.DocumentType.Description,
          FileName: i.Document.FileName,
          householdMember: i.HouseholdMember
            ? `${i.HouseholdMember.FirstName} ${i.HouseholdMember.LastName}`
            : '',
        }
      })
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Application Id',
          align: 'center',
          sortable: true,
          value: 'ApplicationId',
          visible: false,
        },
        {
          text: 'Document Id',
          align: 'center',
          sortable: true,
          value: 'DocumentId',
          visible: false,
        },
        {
          text: 'File Name',
          align: 'center',
          sortable: true,
          value: 'FileName',
          visible: true,
        },
        {
          text: 'Mime Type',
          align: 'center',
          sortable: true,
          value: 'MimeType',
          visible: false,
        },
        {
          text: 'Document Type',
          align: 'center',
          sortable: true,
          value: 'documentType',
          visible: true,
        },
        {
          text: 'Household Member',
          align: 'center',
          sortable: true,
          value: 'householdMember',
          visible: true,
        },
        {
          text: 'Is Deleted',
          align: 'center',
          sortable: true,
          value: 'IsDeleted',
          visible: this.showDeleted,
        },
        {
          text: 'File',
          align: 'center',
          sortable: true,
          value: 'File',
          visible: false,
        },
        /*{
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedBy',
          visible: true,
        },
        {
          text: 'Created Date',
          align: 'center',
          sortable: true,
          value: 'CreatedDate',
          visible: true,
        },*/
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('application', [
      'loadSelectedApplicationApplicationDocuments',
      'loadSelectedApplicationHouseholdMembers',
    ]),
    ...call('document', ['deleteDocument', 'downloadDocument']),
    ...call('applicationDocument', ['deleteApplicationDocument']),
    refresh() {
      this.loadSelectedApplicationApplicationDocuments()
      this.loadSelectedApplicationHouseholdMembers()
    },
    addDocument() {
      this.$refs.applicationDocumentForm.addApplicationDocument(
        this.selectedApplicationApplicationDocumentsId
      )
    },
    download(item) {},
  },
  watch: {},
}
</script>
<style scoped></style>
