<template>
  <div class="wrapper" :style="cssVars">
    <div class="mt-2 pr-8 application-content">
      <ApplicationMainContent
        :application="application"
        :canEdit="canEdit"
      ></ApplicationMainContent>
    </div>
    <div class="fixed-wrapper">
      <span class="sidebar-expand-button">
        <BaseTooltipButton
          small
          @click="expandSidebar = !expandSidebar"
          iconColor="primary"
          :icon="expandSidebar ? 'mdi-chevron-right' : 'mdi-chevron-left'"
          >{{ expandSidebar ? 'Collapse' : 'Expand' }}
        </BaseTooltipButton>
      </span>
      <div class="fixed">
        <ApplicationSidebar :application="application"></ApplicationSidebar>

        <BaseTooltipButton
          v-if="canSubmit"
          :disabled="!hasRequirements"
          icon="mdi-help-circle"
          iconColor="primary"
          @click="$refs.applicationSubmit.submitConfirm(this)"
          buttonRightText="Submit Application"
          :iconButton="false"
        >
          Submit Application
        </BaseTooltipButton>
        <v-card
          v-else-if="beforePeriod || applicationDeadlineExpired || afterPeriod"
          class="pa-2"
        >
          <div v-if="beforePeriod"
            >The application submission period has not yet opened. Submissions
            begin
            {{
              dayjs(application.Period.ApplicationOpenDate).format('MM/DD/YYYY')
            }}
          </div>
          <div v-if="applicationDeadlineExpired">
            The application deadline has expired.
            <br />
            This application can no longer be submitted.
          </div>
          <div v-if="afterPeriod"> The application period has ended </div>
        </v-card>

        <BaseSubmitConfirm
          ref="applicationSubmit"
          :submit="submitApplication"
          @refresh="refresh"
        >
          Once submitted, you will NOT be able to update or make any changes to
          this application! Are you sure you wish to submit now?
        </BaseSubmitConfirm>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import ApplicationMainContent from '@components/form/consumer/applicationMainContent'
import ApplicationSidebar from '@components/form/consumer/applicationSidebar'
import ApplicationStatusHistory from '@classes/ApplicationStatusHistory'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    application: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    ApplicationMainContent,
    ApplicationSidebar,
  },
  data() {
    return {
      expandSidebar: true,
      dayjs,
    }
  },
  computed: {
    ...get('applicationStatusHistory', ['saving']),
    ...get('applicationDocumentType', ['applicationDocumentTypes']),
    ...get('application', [
      'selectedApplicationApplicationStatusHistories',
      'selectedApplicationApplicationDocuments',
      'selectedApplicationHouseholdMembers',
      'applicationStatusHistoriesLoading',
    ]),
    validate() {},
    vuetify() {
      return this.$vuetify
    },
    sidebarWidth() {
      return this.expandSidebar ? 220 : 0
    },
    cssVars() {
      return {
        '--sidebar-width': this.sidebarWidth + 'px',
      }
    },
    canSubmit() {
      return (
        this.canEdit &&
        !this.beforePeriod &&
        !this.applicationDeadlineExpired &&
        !this.afterPeriod
      )
    },
    taxBillExceedsIncomePercentage() {
      if (
        this.application.TotalHouseholdIncomeReported != null &&
        this.application.TaxBillAmount != null &&
        this.application.PropertyTaxGreaterThanIncomePercentage == true
      ) {
        var percentage = this.application.TotalHouseholdIncomeReported * 0.1
        if (this.application.TaxBillAmount > percentage) {
          return true
        } else {
          return false
        }
      } else {
        return null
      }
    },
    hasRequirements() {
      if (
        this.application?.TaxableProperty?.Contact?.Ssn &&
        this.application?.TaxableProperty?.ParcelId &&
        this.application.TotalHouseholdIncomeReported != null &&
        this.application.IsPrimaryResidence &&
        this.application.DoResidentialRequirementsQualify &&
        (this.application.DoHouseholdAssetsQualify ||
          this.taxBillExceedsIncomePercentage) &&
        this.application.CertifySsn &&
        this.application.UsResident &&
        this.application.CorrectAndComplete &&
        this.paymentsMadeOnTime &&
        this.selectedApplicationHouseholdMembers.length > 0 &&
        this.selectedApplicationApplicationDocuments.length > 0 &&
        this.hasDocsForHousehold &&
        this.hasTaxDocs
      ) {
        return true
      } else {
        return false
      }
    },
    payment1Timely() {
      if (
        this.application.Payment1Date &&
        this.application.Period.Payment1Deadline
      ) {
        return (
          new Date(this.application.Payment1Date) <=
          new Date(this.application.Period.Payment1Deadline)
        )
      } else {
        return false
      }
    },
    payment2Timely() {
      if (
        this.application.Payment2Date &&
        this.application.Period.Payment2Deadline
      ) {
        return (
          new Date(this.application.Payment2Date) <=
          new Date(this.application.Period.Payment2Deadline)
        )
      } else {
        return false
      }
    },
    fullPaymentTimely() {
      if (
        this.application.Payment2Date &&
        this.application.Period.FullPaymentDeadline &&
        this.application.Payment1Date
      ) {
        return (
          new Date(this.application.Payment2Date) <=
            new Date(this.application.Period.FullPaymentDeadline) &&
          new Date(this.application.Payment1Date) <=
            new Date(this.application.Period.FullPaymentDeadline)
        )
      } else {
        return false
      }
    },
    paymentsMadeOnTime() {
      let fullPayment
      if (
        (this.payment1Timely && this.payment2Timely) ||
        this.fullPaymentTimely
      ) {
        fullPayment =
          this.application.Payment1Amount + this.application.Payment2Amount
      } else if (!this.payment1Timely && this.payment2Timely) {
        fullPayment = this.application.Payment2Amount
      } else if (this.payment1Timely && !this.payment2Timely) {
        fullPayment = this.application.Payment1Amount
      } else {
        return 0
      }
      return fullPayment
    },
    hasDocsForHousehold() {
      let incomeDocs = true
      this.selectedApplicationHouseholdMembers.forEach((value, index) => {
        let memberDoc = false
        if (value.ApplicationDocuments.length > 0) {
          this.getIncomeDocumentForHouseholdMembers.forEach((value, index) => {
            if (value.Id == 4 || value.Id == 5 || value.Id == 6) {
              memberDoc = true
            }
          })
        }
        if (!memberDoc) {
          incomeDocs = false
        }
      })
      return incomeDocs
    },
    hasTaxDocs() {
      let hasDocument = true
      this.filteredApplicationDocumentTypes.forEach((type) => {
        let taxDoc = false
        if (this.selectedApplicationApplicationDocuments.length > 0) {
          taxDoc =
            this.selectedApplicationApplicationDocuments.findIndex(
              (x) => x.Document.DocumentTypeId == type.Id
            ) > -1
        }
        if (!taxDoc) {
          hasDocument = false
        }
      })
      return hasDocument
    },
    filteredApplicationDocumentTypes() {
      return this.applicationDocumentTypes
        .filter((x) => x.IsRequired)
        .map((type) => {
          return type.DocumentType
        })
        .filter((x) => x.IsActive)
    },
    getIncomeDocumentForHouseholdMembers() {
      return this.applicationDocumentTypes
        .filter((x) => x.IsForHouseholdMember)
        .map((type) => {
          return type.DocumentType
        })
        .filter((x) => x.IsActive)
    },
    withinPeriod() {
      let period = this.application?.Period || this.currentLuPeriod
      let withinPeriod =
        dayjs(period.ApplicationOpenDate) <= dayjs() &&
        dayjs(period.PeriodEndDate) >= dayjs()
      return withinPeriod
    },
    beforePeriod() {
      let period = this.application?.Period || this.currentLuPeriod
      return dayjs(period.ApplicationOpenDate) > dayjs()
    },
    applicationDeadlineExpired() {
      let period = this.application?.Period || this.currentLuPeriod
      return (
        dayjs(period.ApplicationDeadline) < dayjs() &&
        !this.application.ApplicationStatusHistories?.length > 0
      )
    },
    afterPeriod() {
      let period = this.application?.Period || this.currentLuPeriod
      return dayjs(period.PeriodEndDate) < dayjs()
    },
  },
  methods: {
    ...call('applicationStatusHistory', ['saveApplicationStatusHistory']),

    async submitApplication() {
      let appStatus = new ApplicationStatusHistory({
        ApplicationId: this.application.Id,
        StatusId: 1,
      })

      let res = await this.saveApplicationStatusHistory(
        appStatus.removeRelated()
      )
      this.$router.push({ name: 'home' })
    },
    refresh() {
      this.loadSelectedApplication()
    },
  },
  watch: {},
}
</script>
<style vars="{ 'sidebar-width' }" scoped>
.wrap {
  /* position: relative; */
  min-height: 250px;
}

.application-content {
  width: calc(100% - var(--sidebar-width));
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  /* Ensure absolute positioned child elements are relative to this*/
}

.fixed-wrapper {
  /* width: var(--sidebar-width); */
  width: calc(var(--sidebar-width) + 30px);
  position: absolute;
  top: 0;
  right: 0;
  /* Move this out to the left of the site body, leaving a 20px gutter */
  overflow-x: visible;
}

.fixed {
  width: calc(var(--sidebar-width));
  /* overflow-y: auto; */
  /* top: 0; */
  position: fixed;
  margin-left: 30px;
  /* Do not set top / left! */
  height: calc(100vh - 250px);
  overflow-y: auto;

  /* bottom: 0; */
}

.sticky {
  position: sticky;
}

.statusBar {
  overflow-x: hidden;
  position: fixed;
}

.sidebar-expand-button {
  margin-top: 5px;
  width: 30px;
  height: calc(100vh - 250px);
  position: fixed;
  /* left: -30px; */
  /* position: absolute; */
}
</style>
