<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card color="formSectionBg" flat>
      <v-card-title class="primary text-h5 white--text" v-if="dialog">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="10" md="5">
            <v-text-field
              label="First Name"
              v-model="selectedContact.FirstName"
            ></v-text-field>
          </v-col>
          <!-- </v-row>
          <v-row> -->
          <v-col cols="2">
            <v-text-field
              label="Middle Initial"
              v-model="selectedContact.MiddleInitial"
              v-mask="'A'"
            ></v-text-field>
          </v-col>
          <!-- </v-row>
          <v-row> -->
          <v-col cols="12" md="5">
            <v-text-field
              label="Last Name"
              v-model="selectedContact.LastName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="6">
            <v-text-field
              label="Ssn"
              v-model="selectedContact.Ssn"
              return-masked-value
              v-mask="'###-##-####'"
            ></v-text-field>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col sm="12" md="6">
            <v-text-field
              label="Phone"
              v-model="selectedContact.Phone"
              return-masked-value
              v-mask="'(###) ###-####'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              label="Mailing Address 1"
              v-model="selectedContact.MailingAddress1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              label="Mailing Address 2"
              v-model="selectedContact.MailingAddress2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              label="City"
              v-model="selectedContact.City"
            ></v-text-field>
          </v-col>
          <!-- </v-row>
          <v-row> -->
          <v-col cols="4">
            <LuState
              label="State"
              :itemText="$vuetify.breakpoint.smAndDown ? 'Abbr' : 'Name'"
              v-model="selectedContact.StateId"
            ></LuState>
          </v-col>
          <!-- </v-row>
          <v-row> -->
          <v-col cols="7" md="4">
            <v-text-field
              label="Zip"
              v-model="selectedContact.Zip"
              v-mask="['#####', '#####-####']"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
            <v-col sm="12">
              <User
                label="User"
                v-model="selectedContact.UserId"
              ></User>
            </v-col>
          </v-row> -->
      </v-card-text>
      <v-card-actions v-if="dialog">
        <v-btn @click="saveContactDetails" :disabled="saving" color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
    <div class="d-inline-flex float-left" v-if="!dialog">
      <v-btn
        @click="saveContactDetails"
        :disabled="saving"
        color="primary"
        class="mt-4"
        >Next</v-btn
      >
      <v-btn
        v-if="inDialog"
        outlined
        color="primary"
        class="mr-4 mt-4"
        @click="cancelEntry"
        >Cancel</v-btn
      >
    </div>
  </v-form>
</template>
<script>
import { authComputed } from '@state/helpers'
import { get, sync, commit, call } from 'vuex-pathify'
import { mask } from 'vue-the-mask'
import Contact from '@classes/Contact'
//templateTODO: import lookup components you plan to use
import LuState from '@components/select/LuState/LuState-single'
export default {
  //model: {
  //	prop: ''
  //},
  directives: {
    mask,
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  components: {
    //templateTodo: define lookup components you are using
    LuState,
    //User,
  },
  data() {
    return {
      valid: true,
      selectedContact: new Contact(),
      headerText: 'Contact',
    }
  },
  computed: {
    ...get('contact', ['saving']),
    ...authComputed,
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('contact', ['saveContact', 'loadSelectedContact']),
    ...call('auth', ['retrieveUserDetail']),
    async validate() {
      await this.$refs.form.validate()
    },
    editContact(entry) {
      this.selectedContact = new Contact(entry)
      this.headerText = 'Edit Contact'
    },
    // addContact() {
    //   this.headerText = 'Insert Contact'
    //   this.selectedContact = new Contact()
    // },
    async saveContactDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          var res = await this.saveContact(this.selectedContact.root())
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedContact = new Contact()
          this.$refs.form.resetValidation()
          await this.retrieveUserDetail(this.currentUser.Email)
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedContact = new Contact()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
  created() {
    if (this.currentUser?.Contacts.length > 0) {
      this.selectedContact = new Contact(this.currentUser.Contacts[0])
    }
  },
  watch: {},
}
</script>
<style scoped></style>
