<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <ApplicationForm
      ref="applicationForm"
      :dialog.sync="dialog"
      @refresh="$emit('refresh')"
      @itemAdded="$emit('itemAdded', $event)"
    >
    </ApplicationForm>
  </v-dialog>
</template>
<script>
import ApplicationForm from '@components/form/consumer/applicationForm'
export default {
  components: {
    ApplicationForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {},
  methods: {
    editApplication(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.applicationForm.editApplication(entry)
      })
    },
    addApplication() {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.applicationForm.addApplication(entry)
      })
    },
  },
}
</script>
<style></style>
