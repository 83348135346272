<template>
  <BasePanelWithHeader headerText="Properties">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="properties"
      :search="false"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <!-- <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field 
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            single-line
            hide-details>
          </v-text-field>
        </v-col> -->
          <v-col cols="12" sm="auto" class="mt-4 mr-4 ml-auto">
            <BaseTooltipButton
              small
              @click="$refs.taxablePropertyForm.addTaxableProperty(contact.Id)"
              icon="mdi-plus"
              iconColor="white"
              buttonRightText="Add New Property"
              :iconButton="false"
              buttonClass="primary"
              >Add Property
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <!-- @click="$refs.taxablePropertyForm.editTaxableProperty(item)" -->
        <BaseTooltipButton
          small
          iconColor="primary"
          icon="mdi-check-circle"
          @click="$router.push({ query: { propertyId: item.Id } })"
          >Select Taxable Property
        </BaseTooltipButton>
        <!-- :iconButton="false" -->
        <!-- buttonRightText="Select" -->
      </template>
    </BaseSimpleDataTable>
    <TaxablePropertyDialogForm
      ref="taxablePropertyForm"
      :contact="contact"
      @refresh=""
      @itemAdded="itemAdded"
    >
    </TaxablePropertyDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import TaxablePropertyDialogForm from '@components/form/consumer/taxablePropertyDialog'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    properties: {
      type: Array,
    },
    contact: {
      type: Object,
    },
  },
  components: {
    TaxablePropertyDialogForm,
  },
  data() {
    return {
      showInactive: false,
    }
  },
  computed: {
    filteredTaxableProperties() {
      var list = this.properties.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Contact Id',
          align: 'center',
          sortable: true,
          value: 'ContactId',
          visible: false,
        },
        {
          text: 'Parcel Id',
          align: 'center',
          sortable: true,
          value: 'ParcelId',
          visible: true,
        },
        {
          text: 'County',
          align: 'center',
          sortable: true,
          value: 'County.Name',
          visible: true,
        },
        {
          text: 'Latest Period Applied',
          align: 'center',
          sortable: true,
          value: 'latestPeriod',
          visible: true,
        },
        {
          text: 'Street Address 1',
          align: 'center',
          sortable: true,
          value: 'StreetAddress1',
          visible: true,
        },
        {
          text: 'Street Address 2',
          align: 'center',
          sortable: true,
          value: 'StreetAddress2',
          visible: true,
        },
        {
          text: 'City',
          align: 'center',
          sortable: true,
          value: 'City.Name',
          visible: true,
        },
        {
          text: 'State',
          align: 'center',
          sortable: true,
          value: 'State.Abbr',
          visible: true,
        },
        {
          text: 'Zip',
          align: 'center',
          sortable: true,
          value: 'Zip',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    itemAdded(data) {
      this.$router.push({ query: { propertyId: data.Id } })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
