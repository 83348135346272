<template>
  <BasePanelWithHeader>
    <template v-slot:header>
      Application: {{ application.ApplicationNumber
      }}<BaseTooltipButton
        v-if="canEdit"
        icon="mdi-pencil"
        iconColor="white"
        @click="$refs.applicationForm.editApplication(application)"
      >
        Edit Application Details
      </BaseTooltipButton>
      <BaseHelpTooltip
        iconColor="white"
        tooltipText="Review Instructions"
        header="Filing Instructions"
        :maxWidth="850"
        ><div><FilingInstructions></FilingInstructions> </div>
      </BaseHelpTooltip>
    </template>
    <v-row>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div>
          <span class="item-label">Application Period: </span>
          <span class="item-value"> {{ application.Period.Period }}</span>
        </div>
        <div v-if="application.PostmarkDate">
          <span class="item-label">Application Postmark: </span>
          <span class="item-value">
            {{ application.PostmarkDate | formatDate }}</span
          >
          <BaseTooltipIcon
            :icon="postmarkValidation ? 'mdi-check-circle' : 'mdi-close-circle'"
            :color="postmarkValidation ? 'green' : 'red'"
            >Application was Postmarked or Submitted
            {{ postmarkValidation ? 'before' : 'after' }} the
            deadline</BaseTooltipIcon
          >
        </div>
        <div>
          <span class="item-label"> County Household Maximum: </span>
          <span class="item-value"
            >{{ countyHouseholdMaximum | formatMoney }}
          </span>
        </div>
        <div>
          <div>
            <span class="item-label"> Statewide Household Maximum: </span>
            <span class="item-value"
              >{{ statewideHouseholdMaximum | formatMoney }}
            </span></div
          ></div
        >
        <div
          ><span class="item-label"> Total Household Income Reported:</span>
          <span class="item-value">
            {{ application.TotalHouseholdIncomeReported | formatMoney }}</span
          >
          <BaseTooltipIcon
            v-if="application.TotalHouseholdIncomeReported"
            :icon="incomeNotExceeded ? 'mdi-check-circle' : 'mdi-close-circle'"
            :color="incomeNotExceeded ? 'green' : 'red'"
            >Household Income
            {{ incomeNotExceeded ? 'does not exceed' : 'exceeds' }} the maximum
            for this period</BaseTooltipIcon
          >
          <!-- <BaseTooltipIcon
            :icon="incomeMatches ? 'mdi-check-circle' : 'mdi-alert-circle'"
            :color="incomeMatches ? 'green' : 'orange'"
            >Total Household Income Reported
            {{ incomeMatches ? 'matches' : 'does not match' }} the total entries
            in the Household Members section</BaseTooltipIcon
          > -->
        </div>
        <div v-if="application.RefundAmount">
          <span class="item-label">Refund Amount: </span>
          <span class="item-value">
            {{ application.RefundAmount | formatMoney }}</span
          >
        </div>
        <div v-if="application.RefundStatusDate">
          <span class="item-label">Refund Status Date: </span>
          <span class="item-value">
            {{ application.RefundStatusDate | formatDate }}</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div>
          <span class="item-label"> Tax Bill Amount: </span>
          <span class="item-value">
            {{ application.TaxBillAmount | formatMoney }}
          </span>
          <BaseTooltipIcon
            :icon="
              taxBillMatchesPaymentAmounts
                ? 'mdi-check-circle'
                : 'mdi-alert-circle'
            "
            :color="taxBillMatchesPaymentAmounts ? 'green' : 'orange'"
            >Tax Bill Amount
            {{ taxBillMatchesPaymentAmounts ? 'matches' : 'does not match' }}
            the total payment amounts</BaseTooltipIcon
          >
        </div>
        <div>
          <span class="item-label"> Payment 1 Amount: </span>
          <span class="item-value"
            >{{ application.Payment1Amount | formatMoney }}
          </span></div
        ><div>
          <span class="item-label"> Payment 1 Date: </span>
          <span class="item-value"
            >{{ application.Payment1Date | formatDate }}
          </span>
          <BaseTooltipIcon
            :icon="
              payment1Timely || fullPaymentTimely
                ? 'mdi-check-circle'
                : 'mdi-close-circle'
            "
            :color="payment1Timely || fullPaymentTimely ? 'green' : 'red'"
            >Payment 1 was made {{ payment1Timely ? 'before' : 'after' }} the
            deadline{{
              fullPaymentTimely
                ? ' but the full payment was made before the full payment deadline'
                : ''
            }}</BaseTooltipIcon
          >
        </div>
        <div>
          <span class="item-label"> Payment 2 Amount: </span>
          <span class="item-value">
            {{ application.Payment2Amount | formatMoney }}
          </span></div
        >
        <div>
          <span class="item-label"> Payment 2 Date: </span>
          <span class="item-value"
            >{{ application.Payment2Date | formatDate }}
          </span>
          <BaseTooltipIcon
            :icon="payment2Timely ? 'mdi-check-circle' : 'mdi-close-circle'"
            :color="payment2Timely ? 'green' : 'red'"
            >Payment 2 was made {{ payment2Timely ? 'before' : 'after' }} the
            deadline</BaseTooltipIcon
          >
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div>
          <!-- <div>
            <span class="item-label"> On Time Payments: </span>
            <span class="item-value"
              >{{ paymentsMadeOnTime | formatMoney }}
            </span></div
          >-->
          <div>
            <span class="item-label"> Business Deduction: </span>
            <span class="item-value"
              >{{ application.BusinessDeduction | formatMoney }}
            </span>
            <BaseTooltipIcon
              v-if="application.BusinessDeduction >= paymentsMadeOnTime"
              icon="mdi-close-circle"
              color="red"
              >The Business Deduction is greater than the on time
              payments</BaseTooltipIcon
            >
          </div>
          <!--<div>
            <span class="item-label"> Payment Considered For Refund: </span>
            <span class="item-value">
              {{ paymentConsideredForRefund | formatMoney }}
            </span></div
          >
          <div>
            <span class="item-label"> 1/2 Payment: </span>
            <span class="item-value">
              {{ halfPayment | formatMoney }}
            </span></div
          >
          <div>
            <span class="item-label"> County Median: </span>
            <span class="item-value">
              {{ countyMedianTax | formatMoney }}
            </span></div
          >-->
          <!-- <div>
            <span class="item-label"> Calculated Refund: </span>
            <span class="item-value">
              {{ calculatedRefundAmount | formatMoney }}
            </span></div
          >-->
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div v-if="currentStatusDescription">
          <div class="item-label"> Application Status </div>
          <div class="item-value">
            {{ currentStatusDescription }}
          </div>
          <div v-if="currentReasonDescription" class="item-subtext">{{
            currentReasonDescription
          }}</div>
        </div>
        <!--<BaseTooltipButton
          v-if="availableStatuses.length > 0"
          icon="mdi-help-circle"
          iconColor="primary"
          @click="
            $refs.applicationStatusHistoryForm.addApplicationStatusHistory()
          "
          buttonRightText="Change Status"
          :iconButton="false"
        >
          Change Status
        </BaseTooltipButton>-->
      </v-col>
    </v-row>
    <!-- <div> Appliction Number: {{ application.ApplicationNumber }} </div> -->
    <ApplicationDialogForm
      ref="applicationForm"
      @refresh="refreshSelectedApplication"
    >
    </ApplicationDialogForm>
    <ApplicationStatusHistoryDialogForm
      :currentStatus="currentStatus"
      :appId="application.Id"
      ref="applicationStatusHistoryForm"
      @refresh="loadSelectedApplicationApplicationStatusHistories"
    >
    </ApplicationStatusHistoryDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import FilingInstructions from '@components/instructions.vue'
import ApplicationDialogForm from '@components/form/consumer/applicationDialog'
import ApplicationStatusHistoryDialogForm from '@components/form/add-edit/ApplicationStatusHistoryDialog'
import { authMethods, authComputed } from '@state/helpers'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    application: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    ApplicationDialogForm,
    ApplicationStatusHistoryDialogForm,
    FilingInstructions,
  },
  data() {
    return {}
  },
  created() {
    this.loadLuApplicationStatuses()
    this.loadLuIncomeThresholds()
  },
  computed: {
    ...authComputed,
    ...get('application', [
      'selectedApplicationApplicationStatusHistories',
      'selectedApplicationHouseholdMembers',
      'applicationStatusHistoriesLoading',
    ]),
    ...get('luApplicationStatus', ['luApplicationStatuses']),
    ...get('luIncomeThreshold', ['luIncomeThresholds']),
    vuetify() {
      return this.$vuetify
    },
    postmarkValidation() {
      if (
        this.application.PostmarkDate &&
        this.application.Period.ApplicationDeadline
      ) {
        return (
          new Date(this.application.PostmarkDate) <=
          new Date(this.application.Period.ApplicationDeadline)
        )
      } else {
        return false
      }
    },
    calculatedRefund() {},
    countyMedian() {
      if (
        this.application.TaxableProperty &&
        this.application.TaxableProperty.CountyId
      ) {
        return this.application?.Period?.CountyMedians.find(
          (x) => x.CountyId == this.application.TaxableProperty.CountyId
        )
      } else {
        return null
      }
    },

    countyMedianTax() {
      if (this.countyMedian) {
        return this.countyMedian.MedianTax
      }
    },
    taxBillMatchesPaymentAmounts() {
      var paymentSums =
        this.application.Payment1Amount + this.application.Payment2Amount
      return (
        Math.round(Number.parseFloat(paymentSums) * 100) / 100 ==
        this.application.TaxBillAmount
      )
    },
    payment1Timely() {
      if (
        this.application.Payment1Date &&
        this.application.Period.Payment1Deadline
      ) {
        return (
          new Date(this.application.Payment1Date) <=
          new Date(this.application.Period.Payment1Deadline)
        )
      } else {
        return false
      }
    },
    payment2Timely() {
      if (
        this.application.Payment2Date &&
        this.application.Period.Payment2Deadline
      ) {
        return (
          new Date(this.application.Payment2Date) <=
          new Date(this.application.Period.Payment2Deadline)
        )
      } else {
        return false
      }
    },
    fullPaymentTimely() {
      if (
        this.application.Payment2Date &&
        this.application.Period.FullPaymentDeadline &&
        this.application.Payment1Date
      ) {
        return (
          new Date(this.application.Payment2Date) <=
            new Date(this.application.Period.FullPaymentDeadline) &&
          new Date(this.application.Payment1Date) <=
            new Date(this.application.Period.FullPaymentDeadline)
        )
      } else {
        return false
      }
    },
    paymentConsideredForRefund() {
      var paymentDifference =
        this.paymentsMadeOnTime - this.application.BusinessDeduction
      return paymentDifference >= 0 ? paymentDifference : 0
    },
    paymentsMadeOnTime() {
      let fullPayment
      if (
        (this.payment1Timely && this.payment2Timely) ||
        this.fullPaymentTimely
      ) {
        fullPayment =
          this.application.Payment1Amount + this.application.Payment2Amount
      } else if (!this.payment1Timely && this.payment2Timely) {
        fullPayment = this.application.Payment2Amount
      } else if (this.payment1Timely && !this.payment2Timely) {
        fullPayment = this.application.Payment1Amount
      } else {
        return 0
      }
      return Math.round(Number.parseFloat(fullPayment) * 100) / 100
      // return fullPayment
    },
    // halfPayment() {
    //   if (this.paymentConsideredForRefund > 0) {
    //     return (
    //       Math.round(
    //         Number.parseFloat(this.paymentConsideredForRefund / 2) * 100
    //       ) / 100
    //     )
    //   }
    // },
    // calculatedRefundAmount() {
    //   return this.halfPayment > this.countyMedianTax
    //     ? this.countyMedianTax
    //     : this.halfPayment
    // },
    thresholdDate() {
      if (this.application.PostmarkDate) {
        return Date.parse(this.application.PostmarkDate)
      } else if (this.application?.Period.ApplicationDeadline) {
        return Date.parse(this.application?.Period.ApplicationDeadline)
      } else {
        return Date.now()
      }
    },
    activeIncomeThresholds() {
      return this.luIncomeThresholds
        .filter(
          (x) =>
            Date.parse(x.EffectiveDate) <= this.thresholdDate &&
            (x.ExpirationDate == null ||
              Date.parse(x.ExpirationDate) >= this.thresholdDate)
        )
        .sort((a, b) => b.IncomePercentage - a.IncomePercentage)
    },
    fullPaymentThreshold() {
      return this.activeIncomeThresholds.length > 0
        ? this.activeIncomeThresholds.find((x) => x.RefundPercentage == 1)
        : null
    },
    fullPaymentIncomePercentage() {
      return this.fullPaymentThreshold?.IncomePercentage ?? 1.25
    },
    mappedStateIncomeThresholds() {
      return this.activeIncomeThresholds.map((x) => {
        var maxIncome =
          (this.application?.Period?.HouseholdMaximum /
            this.fullPaymentIncomePercentage) *
          x.IncomePercentage
        return {
          ...x,
          maxIncome,
        }
      })
    },
    mappedCountyIncomeThresholds() {
      return this.activeIncomeThresholds.map((x) => {
        var maxIncome =
          (this.countyMedian.MedianIncome / this.fullPaymentIncomePercentage) *
          x.IncomePercentage
        return {
          ...x,
          maxIncome,
        }
      })
    },
    statewideHouseholdMaximum() {
      //if we have income thresholds we use the highest percentage from those
      if (this.mappedStateIncomeThresholds.length > 0) {
        return this.mappedStateIncomeThresholds[0].maxIncome
      }
      return this.application?.Period?.HouseholdMaximum
    },
    countyHouseholdMaximum() {
      if (this.countyMedian) {
        //if we have income thresholds we use the highest percentage from those
        if (this.mappedCountyIncomeThresholds.length > 0) {
          return this.mappedCountyIncomeThresholds[0].maxIncome
        }
        return this.countyMedian.MedianIncome
      }
    },
    maxIncome() {
      return this.statewideHouseholdMaximum > this.countyHouseholdMaximum
        ? this.statewideHouseholdMaximum
        : this.countyHouseholdMaximum
    },
    incomeNotExceeded() {
      if (
        this.application.TotalHouseholdIncomeReported != null &&
        this.statewideHouseholdMaximum
      ) {
        return this.application.TotalHouseholdIncomeReported <= this.maxIncome
      } else {
        return true
      }
    },
    incomeMatches() {
      let totalIncome = this.selectedApplicationHouseholdMembers
        .map((item) => {
          let totalIncome =
            item.TaxableIncome +
            item.NonTaxableInterest +
            item.NonTaxableDistributions +
            item.NonTaxableSs +
            item.OtherIncome +
            item.PriorYearPtrsRefund
          return {
            totalIncome,
          }
        })
        .reduce((sum, item) => {
          return sum + item.totalIncome
        }, 0)
      return totalIncome == this.application.TotalHouseholdIncomeReported
    },

    currentStatusDescription() {
      if (this.currentStatus && this.currentStatus.Status) {
        return this.currentStatus.Status.Description
      }
    },
    currentReasonDescription() {
      if (this.currentStatus && this.currentStatus.Reason) {
        return this.currentStatus.Reason.Description
      }
    },
    currentStatus() {
      if (
        this.selectedApplicationApplicationStatusHistories &&
        this.selectedApplicationApplicationStatusHistories.length > 0
      ) {
        return this.selectedApplicationApplicationStatusHistories.reduce(
          (m, v, i) =>
            new Date(v.CreatedDate).getTime() >
              new Date(m.CreatedDate).getTime() && i
              ? v
              : m
        )
      }
      return null
    },
    availableStatuses() {
      let statuses = this.luApplicationStatuses.filter((x) => x.IsActive)
      if (this.currentStatus && this.currentStatus.StatusId > 0) {
        //we have a current status and need to find the statuses available as the next status
        let match = statuses.find(
          (x) => x.Id == this.currentStatus.StatusId
        )?.NextAvailableStatuses
        // filter the list down to ones available to one of the current user roles
        if (match) {
          let availableStatuses = match
            .filter((x) =>
              x.Roles.some(
                (a) =>
                  this.currentUser.Roles.findIndex((r) => r.Id == a.Id) > -1
              )
            )
            //and map the next status associated with the record
            .map((i) => {
              return i.NextStatus
            })
          return availableStatuses
        }
      } else {
        //a status has not been entered, so the only available one is the in progress (DAT) status
        return statuses.filter((x) => x.Id == 1)
      }
    },
  },
  methods: {
    ...call('application', [
      'loadSelectedApplicationApplicationStatusHistories',
      'refreshSelectedApplication',
    ]),
    loadLuApplicationStatuses: call(
      'luApplicationStatus/loadLuApplicationStatuses'
    ),
    loadLuIncomeThresholds: call('luIncomeThreshold/loadLuIncomeThresholds'),
    editApplication() {},
    changeStatus() {},
  },
  watch: {},
}
</script>
<style scoped>
.item-label {
  font-weight: bold;
}
.item-value {
  font-weight: normal;
}
.item-subtext {
  font-size: smaller;
  font-weight: normal;
}
</style>
