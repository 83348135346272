<template>
  <BasePanelWithHeader :collapsable="true" headerText="Household Members">
    <!--<template v-slot:header>
      Household Members<BaseTooltipButton
        v-if="canEdit"
        icon="mdi-plus-circle"
        iconColor="white"
        @click.stop="addHouseholdMember"
      >
        Add Household Member
      </BaseTooltipButton>
    </template>-->
    <template v-slot:header>
      <v-row>
        <v-col cols="12" sm="auto">Household Members</v-col>
        <v-col cols="12" sm="auto">
          <BaseTooltipButton
            v-if="canEdit"
            icon="mdi-plus-circle"
            iconColor="white"
            buttonClass="primary--text"
            @click.stop="addHouseholdMember"
            buttonRightText="Add Household Member"
            :iconButton="false"
          >
            Add Household Member
          </BaseTooltipButton>
        </v-col>
      </v-row>
    </template>
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="mappedHouseholdMembers"
      :search="false"
      :loading="householdMembersLoading"
    >
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          v-if="canEdit"
          small
          @click="$refs.householdMemberForm.editHouseholdMember(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Household Member
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          v-if="canEdit"
          :disabled="
            item.ApplicationDocuments && item.ApplicationDocuments.length > 0
          "
          small
          @click="$refs.householdMemberDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Household Member
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsTaxPayer="{ item }">{{
        item.IsTaxPayer ? 'Yes' : 'No'
      }}</template>
      <template v-slot:item.NoIncomeTaxFiled="{ item }">{{
        item.NoIncomeTaxFiled ? 'No' : 'Yes'
      }}</template>
      <template v-slot:item.TaxableIncome="{ item }">{{
        item.TaxableIncome | formatMoney
      }}</template>
      <template v-slot:item.NonTaxableInterest="{ item }">{{
        item.NonTaxableInterest | formatMoney
      }}</template>
      <template v-slot:item.NonTaxableDistributions="{ item }">{{
        item.NonTaxableDistributions | formatMoney
      }}</template>
      <template v-slot:item.NonTaxableSs="{ item }">{{
        item.NonTaxableSs | formatMoney
      }}</template>
      <template v-slot:item.OtherIncome="{ item }">{{
        item.OtherIncome | formatMoney
      }}</template>
      <template v-slot:item.PriorYearPtrsRefund="{ item }">{{
        item.PriorYearPtrsRefund | formatMoney
      }}</template>
      <template v-slot:item.totalIncome="{ item }">{{
        item.totalIncome | formatMoney
      }}</template>
      <template v-slot:footer>
        <v-row>
          <v-spacer></v-spacer>
          <v-col sm="auto" class="ma-4"
            >Total Household Income:
            {{ totalHouseholdIncome | formatMoney }}
            <BaseTooltipIcon
              :icon="
                householdIncomeMatches ? 'mdi-check-circle' : 'mdi-close-circle'
              "
              :color="householdIncomeMatches ? 'green' : 'red'"
              >Total Household Income Reported
              {{
                householdIncomeMatches ? 'matches' : 'does not match'
              }}</BaseTooltipIcon
            >
          </v-col>
        </v-row>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="householdMemberDelete"
      :delete="deleteHouseholdMember"
      @refresh="updateHouseholdMembers"
    >
      Are you sure you want to delete this household member?
    </BaseDeleteConfirm>
    <HouseholdMemberDialogForm
      ref="householdMemberForm"
      @refresh="updateHouseholdMembers"
    >
    </HouseholdMemberDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import HouseholdMemberDialogForm from '@components/form/add-edit/HouseholdMemberDialog'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    HouseholdMemberDialogForm,
  },
  data() {
    return {}
  },
  computed: {
    ...get('application', [
      'selectedApplication',
      'priorApplication',
      'selectedApplicationHouseholdMembers',
      'selectedApplicationHouseholdMembersId',
      'householdMembersLoading',
    ]),
    ...get('luPeriod', ['currentLuPeriod']),
    mappedHouseholdMembers() {
      return this.selectedApplicationHouseholdMembers.map((item) => {
        let totalIncome =
          item.TaxableIncome +
          item.NonTaxableInterest +
          item.NonTaxableDistributions +
          item.NonTaxableSs +
          item.OtherIncome +
          item.PriorYearPtrsRefund
        return {
          ...item,
          totalIncome: Math.round(Number.parseFloat(totalIncome) * 100) / 100,
        }
      })
    },
    totalHouseholdIncome() {
      let totalIncome = this.mappedHouseholdMembers.reduce((sum, item) => {
        return sum + item.totalIncome
      }, 0)
      return Math.round(Number.parseFloat(totalIncome) * 100) / 100
    },
    householdIncomeMatches() {
      return (
        this.totalHouseholdIncome ==
        this.selectedApplication.TotalHouseholdIncomeReported
      )
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Application Id',
          align: 'center',
          sortable: true,
          value: 'ApplicationId',
          visible: false,
        },
        {
          text: 'Property Tax Payer',
          align: 'center',
          sortable: true,
          value: 'IsTaxPayer',
          visible: true,
        },
        {
          text: 'First Name',
          align: 'center',
          sortable: true,
          value: 'FirstName',
          visible: true,
        },
        {
          text: 'Last Name',
          align: 'center',
          sortable: true,
          value: 'LastName',
          visible: true,
        },
        {
          text: 'Income Tax Filed',
          align: 'center',
          sortable: true,
          value: 'NoIncomeTaxFiled',
          visible: true,
        },
        {
          text: 'Taxable Income',
          align: 'center',
          sortable: true,
          value: 'TaxableIncome',
          visible: true,
        },
        {
          text: 'Non Taxable Interest',
          align: 'center',
          sortable: true,
          value: 'NonTaxableInterest',
          visible: true,
        },
        {
          text: 'Non Taxable Distributions',
          align: 'center',
          sortable: true,
          value: 'NonTaxableDistributions',
          visible: true,
        },
        {
          text: 'Non Taxable SS',
          align: 'center',
          sortable: true,
          value: 'NonTaxableSs',
          visible: true,
        },
        {
          text: 'Other Income',
          align: 'center',
          sortable: true,
          value: 'OtherIncome',
          visible: true,
        },
        {
          text: 'Prior Year PTRS Refund',
          align: 'center',
          sortable: true,
          value: 'PriorYearPtrsRefund',
          visible: true,
        },
        {
          text: 'Total Income',
          align: 'center',
          sortable: true,
          value: 'totalIncome',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('application', [
      'loadSelectedApplicationHouseholdMembers',
      'patchApplication',
      'refreshSelectedApplication',
      'loadPriorApplication',
    ]),
    ...call('householdMember', ['deleteHouseholdMember']),
    ...call('luPeriod', ['loadCurrentLuPeriod']),
    async updateHouseholdMembers() {
      await this.loadSelectedApplicationHouseholdMembers()
      let income = this.totalHouseholdIncome
      let patchOp = {
        op: 'replace',
        path: '/TotalHouseholdIncomeReported',
        value: income,
      }
      // await this.patchApplication({
      //   Id: this.selectedApplication.Id,
      //   patchDoc: [patchOp],
      // })
      this.refreshSelectedApplication()
    },
    addHouseholdMember() {
      let member = { ApplicationId: this.selectedApplicationHouseholdMembersId }
      if (
        this.selectedApplicationHouseholdMembers &&
        this.selectedApplicationHouseholdMembers.length == 0 &&
        this.selectedApplication &&
        this.selectedApplication.TaxableProperty &&
        this.selectedApplication.TaxableProperty.Contact
      ) {
        let contact = this.selectedApplication.TaxableProperty.Contact
        if (contact && contact.Id > 0) {
          member.FirstName = contact.FirstName
          member.LastName = contact.LastName
          member.IsTaxPayer = true
          if (this.priorApplication) {
            member.PriorYearPtrsRefund = this.priorApplication.RefundAmount
          }
        }
      }
      console.devlog(member)
      this.$refs.householdMemberForm.addHouseholdMember(member)
    },
  },
  async created() {
    if (!this.currentLuPeriod) {
      let res = await this.loadCurrentLuPeriod()
      console.devlog(this.currentLuPeriod, periodId, res.data)
    }
    let periodId = this.currentLuPeriod.Id
    await this.loadPriorApplication({
      contactId: this.selectedApplication?.TaxableProperty?.Contact?.Id,
      periodId,
    })
  },
  watch: {},
}
</script>
<style scoped></style>
