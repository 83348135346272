<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3" tile>
      <v-card-title class="primary text-h5 white--text">
        {{ headerText }}
        <BaseHelpTooltip
          iconColor="white"
          tooltipText="Review Instructions"
          header="Filing Instructions"
          :maxWidth="850"
          ><div><FilingInstructions></FilingInstructions> </div>
        </BaseHelpTooltip>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <div class="text-h5 font-weight-bold">
              Wyoming Residency Requirement
            </div>
            <p>
              The law requires the applicant taxpayer to have been a Wyoming
              resident for a period of at least five (5) years immediately
              preceding the year in which he/she applies for property tax refund
              under this program. A resident is a person who physically lives in
              Wyoming for more than one-half (1/2) of the year; however, a
              person does not lose his residency if his absence is for business
              of the state or federal government, including military service.
            </p>

            <div class="text-subtitle-1 font-weight-medium"
              >Have you been a resident of the State of Wyoming for the past
              five (5) years, according to the definition in the preceding
              statement?</div
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-checkbox
              label="Check this box if Residential Requirements Qualify"
              v-model="selectedApplication.DoResidentialRequirementsQualify"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="text-subtitle-1 font-weight-medium"
              >Is this property your primary residence?</div
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-checkbox
              label="Check this box if the property is your Primary Residence"
              v-model="selectedApplication.IsPrimaryResidence"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-h5 font-weight-bold">
              Property Tax Paid On Applicant's Primary Residence
            </div>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12">
                <div class="text-subtitle-1 font-weight-medium">
                  What is the post mark date on tax bill issued:
                </div>
              </v-col>
              <v-col cols="12">
                <BaseDatePickerWithText
                  label="Postmark Date mm/dd/yyyy"
                  v-model="selectedApplication.PostmarkDate"
                ></BaseDatePickerWithText>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12">
                <div class="text-subtitle-1 font-weight-medium">
                  What was the amount of the property tax bill issued:
                </div>
              </v-col>
              <v-col cols="12">
                <BaseCurrencyField
                  label="Tax Bill Amount"
                  v-model="selectedApplication.TaxBillAmount"
                >
                </BaseCurrencyField>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-subtitle-1 font-weight-medium">
              If any portion of this residence was used for business purposes
              during the year, enter the amount of the property tax that was
              claimed as a business expense here:
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <BaseCurrencyField
              label="Business Deduction"
              v-model="selectedApplication.BusinessDeduction"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></BaseCurrencyField>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12">
            <div class="text-subtitle-1 font-weight-medium">
              Enter the total household income from the most recent tax returns:
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <BaseCurrencyField
              label="Total Household Income Reported"
              v-model="selectedApplication.TotalHouseholdIncomeReported"
            ></BaseCurrencyField>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col cols="12">
            <div class="text-subtitle-1 font-weight-medium">
              Please enter the taxes paid as provided on the property tax
              payment receipt(s) issued by the county treasurer:
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <BaseCurrencyField
              label="Payment 1 Amount"
              v-model="selectedApplication.Payment1Amount"
            ></BaseCurrencyField>
          </v-col>
          <v-col cols="12" sm="6">
            <BaseDatePickerWithText
              label="Payment 1 Date mm/dd/yyyy"
              v-model="selectedApplication.Payment1Date"
            >
            </BaseDatePickerWithText>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <BaseCurrencyField
              label="Payment 2 Amount"
              v-model="selectedApplication.Payment2Amount"
            ></BaseCurrencyField>
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatePickerWithText
              label="Payment 2 Date mm/dd/yyyy"
              v-model="selectedApplication.Payment2Date"
            >
            </BaseDatePickerWithText>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="text-h5 font-weight-bold">
              Value Of Household Assets
            </div>
          </v-col>
        </v-row>
        <p class="text-subtitle-1 font-weight-medium">
          The law says a taxpayer does NOT qualify for this property tax refund
          program<br />
          if the value of certain kinds of assets exceeds
          {{ currentLuPeriod.AssetMaximum | formatMoney }} per adult member
          <br />
          of the household. Here are the assets that count toward this
          limitation:<br /><br />

          &emsp;&emsp;1.) Bank accounts;<br />
          &emsp;&emsp;2.) Investments (stocks, bonds, mutual funds, certificates
          of deposit, etc.);<br />
          &emsp;&emsp;3.) Real estate other than the house subject to this
          application; and,<br />
          &emsp;&emsp;4.) Motor vehicles in excess of one allowed for each adult
          member of the household.<br /><br />

          Is the value of the assets listed above owned by each adult member of
          the household <br />
          LESS THAN {{ currentLuPeriod.AssetMaximum | formatMoney }}
        </p>
        <v-row>
          <v-col cols="12" sm="5">
            <v-checkbox
              :label="`Check here to certify that household assets total value is less than ${$options.filters.FormatMoney(
                currentLuPeriod.AssetMaximum
              )}: `"
              v-model="selectedApplication.DoHouseholdAssetsQualify"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="1">
            <div class="center-vert"> Or </div>
          </v-col>
          <v-col cols="12" sm="5">
            <v-checkbox
              :label="`Check here to certify that your total tax bill exceeds 10% of your total reported household income`"
              v-model="
                selectedApplication.PropertyTaxGreaterThanIncomePercentage
              "
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="text-h5 font-weight-bold">
              Certification<br /><br />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-subtitle-1 font-weight-bold">
              By checking the following boxes I am signing and certifying under
              penalty of perjury that:
            </div>
          </v-col>
        </v-row>
        <p class="text-subtitle-1 font-weight-medium">
          1.) The social security number shown on this form is my correct
          taxpayer identification number (or I am waiting for a number to be
          issued to me).<br />
        </p>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="By checking this box you certify the SSN provided is legally mine and correct."
              v-model="selectedApplication.CertifySsn"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <p class="text-subtitle-1 font-weight-medium">
            2.) I am not subject to backup withholding because <br />
            &emsp;&emsp;(a) I am exempt from backup withholding, or <br />
            &emsp;&emsp;(b) I have not been notified by the IRS that I am
            subject to backup withholding <br />
            &emsp;&emsp;as a result of failure to report all interest and
            dividends, or <br />
            &emsp;&emsp;(c) the IRS has notified me that I am no longer subject
            to backup withholding.<br />
          </p>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="By checking this box you certify that you are NOT subject to any form of backup withholding outlined above"
              v-model="selectedApplication.NoBackupWithholding"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <p class="text-subtitle-1 font-weight-medium">
            3.) I am a U.S. person (including a U.S. resident alien). <br />
          </p>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="By checking this box you certify that you a US resident."
              v-model="selectedApplication.UsResident"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <p class="text-subtitle-1 font-weight-medium">
            4.) The information entered on this application is true, correct and
            complete to the best of my knowledge and belief.<br />
          </p>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="By checking this box you certify that this application is correct and complete to the best of your knowledge."
              v-model="selectedApplication.CorrectAndComplete"
            ></v-checkbox>
          </v-col>
        </v-row>

        <!--  
        </v-row>
        
        <v-row>
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Tax Bill Attached"
              v-model="selectedApplication.IsTaxBillAttached"
            ></v-checkbox>
          </v-col>
           </v-row>
      <v-row> -->
        <!-- <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Payment Receipt Attached"
              v-model="selectedApplication.IsPaymentReceiptAttached"
            ></v-checkbox>
          </v-col>
         </v-row>
        <v-row> 
        <v-col cols="12" sm="3">
            <v-checkbox
              label="Are Household Details Provided"
              v-model="selectedApplication.AreHouseholdDetailsProvided"
            ></v-checkbox>
          </v-col>
         </v-row>-->

        <v-row>
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Irs Attached"
              v-model="selectedApplication.IsIrsAttached"
              class="d-none"
            ></v-checkbox>
          </v-col>
        </v-row>

        <!--  <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Application Signed"
              v-model="selectedApplication.IsApplicationSigned"
            ></v-checkbox>
          </v-col>
        </v-row>
         //todo: will set this automatically or at a later date -->
        <!--<v-row v-if="inDialog">
          <v-col cols="12" sm="6" md="3">
            <BaseCurrencyField
              label="Refund Amount"
              v-model="selectedApplication.RefundAmount"
            ></BaseCurrencyField>
          </v-col>
          </v-row>
        <v-row v-i="inDialog"> 
          <v-col cols="12" sm="6" md="3">
            <BaseDatePickerWithText
              label="Refund Status Date mm/dd/yyyy"
              v-model="selectedApplication.RefundStatusDate"
            ></BaseDatePickerWithText>
          </v-col>
        </v-row>-->
        <!-- 
        
        
         -->
        <!-- <v-row>
            <v-col cols="12">
              <LuPeriod
                label="Period"
                v-model="selectedApplication.PeriodId"
                  :rules="[v => !!v || 'required']"
              ></LuPeriod>
            </v-col>
          </v-row> -->
        <!-- <v-row>
            <v-col cols="12">
              <TaxableProperty
                label="Taxable Property"
                v-model="selectedApplication.TaxablePropertyId"
                  :rules="[v => !!v || 'required']"
              ></TaxableProperty>
            </v-col>
          </v-row> -->
        <!-- HouseholdMembers Join Items-->
        <!-- <v-row>
            <v-col cols="12">
              <ApplicationDocument
                label="Application Documents"
                v-model="selectedApplication.HouseholdMembers"
                selfKey="ApplicationId"
                :selfId="selectedApplication.Id"
                relatedItemKey="HouseholdMemberId"
                joinItemName="HouseholdMember"
              ></ApplicationDocument>
            </v-col>
          </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveApplicationDetails"
          :disabled="saving"
          color="primary"
          >{{ inDialog ? 'Save' : 'Next' }}</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import FilingInstructions from '@components/instructions.vue'
import { get, sync, commit, call } from 'vuex-pathify'
import Application from '@classes/Application'
import LuPeriod from '@classes/LuPeriod'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
    property: {
      type: Object,
    },
  },
  created() {
    if (!this.inDialog) {
      this.addApplication(this.property.Id)
    }
  },
  components: { FilingInstructions },
  data() {
    return {
      valid: true,
      selectedApplication: new Application(),
      headerText: 'New Application',
    }
  },
  computed: {
    ...get('application', ['saving']),
    ...get('luPeriod', ['currentLuPeriod', 'errorMessage']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('application', ['saveApplication']),
    ...call('luPeriod', ['loadCurrentLuPeriod']),
    async validate() {
      await this.$refs.form.validate()
    },
    editApplication(entry) {
      this.selectedApplication = new Application(entry)
      this.headerText = 'Edit Application'
    },
    addApplication(propertyId) {
      this.headerText = 'Application Form'
      if (propertyId) {
        this.selectedApplication = new Application({
          TaxablePropertyId: propertyId,
          PeriodId: this.currentLuPeriod.Id,
        })
      } else {
        this.selectedApplication = new Application()
      }
    },
    async saveApplicationDetails() {
      await this.validate()
      console.devlog({ valid: this.valid })
      if (this.valid) {
        try {
          let app = this.selectedApplication.root()
          if (app.TaxablePropertyId == 0) {
            app.TaxablePropertyId = this.property.Id
          }
          if (app.PeriodId == 0) {
            app.PeriodId = this.currentLuPeriod.Id
          }
          let res = await this.saveApplication(app)
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedApplication = new Application()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedApplication = new Application()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
  watch: {},
}
</script>
<style scoped>
.center-vert {
  display: flex;
  height: 100%;
  align-content: center;
  flex-wrap: wrap;
  align-self: center;
}
</style>
